




































import { computed, defineComponent, onBeforeUnmount, reactive, watch } from "@vue/composition-api";
import { BASE_URL } from "@/utils/const";

import * as api from "@/services";
import { ITngQrCodeResponse, IOmiRequest } from "@/types";
export default defineComponent({
  components: {
    TngFailedCard: () => import("@/views/components/paymentTng/tng-failed-card.vue"),
    TngPaymentCard: () => import("@/views/components/paymentTng/tng-payment-card.vue"),
    TngSucceedCard: () => import("@/views/components/paymentTng/tng-succeed-card.vue"),
    TngQrcodeDialog: () => import("@/views/widget/dialog/tng-qrcode-dialog.vue")
  },
  setup() {
    const state = reactive({
      showQrcode: false,
      orderNo: "",
      retry: 0,
      omiStatus: "INIT",
      omiRequest: {} as IOmiRequest,
      step: 1,
      formData: {},
      loading: false,
      value: 0,
      timeStart: -1
    });

    const qrcodeImage = computed(() => BASE_URL + "/api/v1/GetTngQrCode?orderNo=" + state.orderNo);

    watch(
      () => state.retry,
      () => {
        if (state.showQrcode) {
          tngGetTransactionInfo();
        }
      }
    );

    onBeforeUnmount(() => (state.showQrcode = false));

    function handleRefreshQrCode() {
      state.step = 1;
      state.retry = -1;
      requestQrCode(state.formData);
    }

    function handleCancel() {
      state.value = 0;
      state.loading = false;
    }

    async function requestQrCode(formData) {
      try {
        state.loading = true;
        state.formData = formData;

        await api.tngRequestQrCode(formData).then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data: ITngQrCodeResponse = response as any;

          const d = new Date();
          state.timeStart = d.getTime();

          if (data.status == "success") {
            state.orderNo = data.orderNo;

            setTimeout(() => {
              state.showQrcode = true;
              incrementRetry();
            }, 50);

            //alert(JSON.stringify(data));
          }
        });
      } catch (e) {
        state.showQrcode = false;
        state.loading = false;
        alert(JSON.stringify(e));
        // console.log("fetch data failed..", e);
      } finally {
        //
      }
    }

    async function tngGetTransactionInfo() {
      const params = { orderNo: state.orderNo };

      try {
        // const data = (await api.tngGetTransactionInfo(params)) as any;

        await api.tngGetTransactionInfo(params).then((response) => {
          const d = new Date();
          const now = d.getTime();
          state.value = Math.floor((now - this.timeStart) / 1500);

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const data = response as any;
          if (data.status == "success") {
            state.omiRequest = data.omiRequest;
            state.omiStatus = state.omiRequest.status + ":" + state.retry;

            if (this.omiRequest.status == "PENDING") {
              setTimeout(() => {
                incrementRetry();
              }, 3000);
            } else if (this.omiRequest.status == "SUCCEED") {
              state.showQrcode = false;
              state.loading = false;
              gotoStep(2);
            } else if (this.omiRequest.status == "FAIL") {
              state.showQrcode = false;
              state.loading = false;
              gotoStep(3);
            }
          }
        });
      } catch (e) {
        alert(JSON.stringify(e));
        // console.log("fetch data failed..", e);
      } finally {
        // console.log("finally");
      }
    }

    function gotoStep(step) {
      setTimeout(() => {
        state.step = step;
      }, 300);
    }

    function incrementRetry() {
      if (state.showQrcode) {
        state.retry = (state.retry + 1) % 60;

        // Enable this to test for the success
        // if (this.retry > 3) {
        //   this.orderNo = "AGCF-1000025";
        //   this.tngGetTransactionInfo();
        // }
      }
    }

    return { state, qrcodeImage, requestQrCode, handleRefreshQrCode, handleCancel };
  }
});
