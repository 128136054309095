import i18n from "@/plugins/i18n";

export const chartOptionsSmall = () => {
  const transparentLabel = {
    normal: {
      color: "transparent"
    }
  };

  const setupLabel = (label, x, y, color = "green") => {
    const opt = {
      name: label,
      x: x,
      y: y,
      value: 1,
      symbol: "rectangle",
      symbolSize: [160, 64],
      label: {
        lineHeight: 15,
        textStyle: {
          fontSize: 13
        },
        formatter: () => {
          return i18n.t(label);
        }
      },
      itemStyle: {
        normal: {
          color: color
        }
      }
    };

    return opt;
  };

  const setupText = (label: string, x, y) => {
    const opt = {
      name: label,
      x: x,
      y: y,
      value: 1,
      symbol: "rectangle",
      symbolSize: [160, 64],
      label: {
        show: true,
        position: "inside",
        textStyle: {
          fontSize: 12,
          color: "#000"
        },
        formatter: () => {
          return i18n.t(label);
        }
      }
    };

    opt["itemStyle"] = transparentLabel;
    return opt;
  };

  // const lineStyleBlue = {
  //   normal: {
  //     opacity: 0.9,
  //     color: "#b0c090",
  //     type: "solid",
  //     width: 1.3
  //   }
  // };

  const lineStyleDashed = {
    normal: {
      opacity: 0.9,
      color: "orange",
      type: "dashed",
      width: 1.3
    }
  };

  const setupNode = (label, x, y) => {
    const opt = {
      name: label,
      x: x,
      y: y,
      z: 100,
      symbol: "circle",
      symbolSize: 1,
      symbolOffset: [1.2, 0],
      label: {
        position: "inside",
        show: false
      },
      itemStyle: {
        normal: {
          color: "blue"
        }
      }
    };
    return opt;
  };

  const setupDashNode = (label: string, x: number, y: number) => {
    const opt = {
      name: label,
      x: x,
      y: y,
      z: 100,
      symbol: "circle",
      symbolSize: 1,
      symbolOffset: [1.2, 0],
      label: {
        position: "inside",
        show: false
      }
    };

    return opt;
  };

  const setupLink = (source, target, lineStyle?) => {
    const opt = { source: source, target: target };

    if (lineStyle != undefined) {
      opt["lineStyle"] = lineStyle;
    }

    return opt;
  };

  const opt = {
    title: {
      top: "top",
      left: "left",
      text: i18n.t("org-title"),
      textStyle: {
        color: "green",
        fontWeight: "normal",
        fontSize: 42
      }
    },
    tooltip: {
      trigger: "none"
    },
    animationDurationUpdate: 1500,
    animationEasingUpdate: "quinticInOut",
    textStyle: {
      color: "#000"
    },
    series: [
      {
        type: "graph",
        top: 110,
        left: "left",
        layout: "none",
        tooltip: {
          backgroundColor: "skyblue"
          //formatter: "{b} <br/>{a} : {c} h "
        },
        symbolSize: 1,
        roam: false,
        label: {
          normal: {
            show: true,
            position: "inside",
            textStyle: {
              fontSize: 12,
              color: "#fff",
              fontWeight: "BOLD"
            }
          }
        },
        edgeSymbol: ["none", "none"],
        edgeSymbolSize: [1, 1],

        data: [
          // root
          // setupLabel(title[0].label, 0, 100),
          setupLabel("label-president", 150, 0),
          setupLabel("label-board", 400, 0),

          // level 1
          setupLabel("label-compliance", 200, 150),

          setupLabel("label-executive", 550, 100),
          setupLabel("label-investment", 550, 200),
          setupLabel("label-treasury", 550, 300),

          // level 2
          setupLabel("label-administration", 200, 450, "orange"),
          setupLabel("label-finance", 450, 450, "orange"),

          // level 3
          setupLabel("label-service", 325, 560, "orange"),

          // level 1 - Node
          setupNode("node-board", 340, 0),
          setupNode("node-complicance", 340, 150),

          setupNode("node-project", 400, 380),

          setupNode("node-executive", 400, 100),
          setupNode("node-investment", 400, 200),
          setupNode("node-treasury", 400, 300),

          setupNode("node-administration", 200, 380),
          setupNode("node-finance", 450, 380),

          setupNode("node-service-1", 200, 560),
          setupNode("node-service-2", 450, 560),

          setupText("board-level", 720, 340),
          setupText("project-level", 720, 385),

          setupDashNode("p0", 60, 360),
          setupDashNode("p1", 800, 360)
        ],
        // links: [],
        // link the object together
        links: [
          setupLink("label-president", "label-board"),

          setupLink("node-complicance", "label-compliance"),
          setupLink("node-board", "node-complicance"),

          setupLink("label-board", "node-project"),

          setupLink("label-executive", "node-executive"),
          setupLink("label-investment", "node-investment"),
          setupLink("label-treasury", "node-treasury"),

          setupLink("node-administration", "node-finance"),
          setupLink("node-administration", "label-administration"),
          setupLink("node-finance", "label-finance"),

          setupLink("node-service-1", "label-administration"),
          setupLink("node-service-1", "label-service"),

          setupLink("node-service-2", "label-finance"),
          setupLink("node-service-2", "label-service"),
          {
            source: "p0",
            target: "p1",
            lineStyle: lineStyleDashed
          }
        ],
        lineStyle: {
          normal: {
            opacity: 1,
            color: "#b0c090",
            type: "solid",
            curveness: 0,
            width: 1.3
          }
        }
      }
    ]
  };

  return opt;
};
