







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    ProjectsEn: () => import("@/views/components/projects/projects-en.vue"),
    ProjectsZh: () => import("@/views/components/projects/projects-zh.vue")
  }
});
