





























import { defineComponent, computed } from "@vue/composition-api";
import { chartOptions } from "@/echarts/org-chart-options";
import { chartOptionsSmall } from "@/echarts/org-chart-options-small";

export default defineComponent({
  components: {
    Management2: () => import("@/views/components/management/management.vue"),
    Management1: () => import("@/views/components/management/management1.vue")
  },

  setup() {
    const option = computed(function () {
      return chartOptions();
    });

    const optionSmall = computed(function () {
      return chartOptionsSmall();
    });

    return {
      option,
      optionSmall
    };
  }
});
