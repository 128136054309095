import i18n from "@/plugins/i18n";

export const chartOptions = () => {
  // const transparentLabel = {
  //   normal: {
  //     color: "transparent"
  //   }
  // };

  const setupLabel = (label, x, y, color = "green") => {
    const opt = {
      name: label,
      x: x,
      y: y,
      value: 1,
      symbol: "rectangle",
      symbolSize: [180, 64],
      label: {
        lineHeight: 15,
        textStyle: {
          fontSize: 13
        },
        formatter: () => {
          return i18n.t(label);
        }
      },
      itemStyle: {
        normal: {
          color: color
        }
      }
    };

    return opt;
  };

  const setupText = (label: string, x, y) => {
    const opt = {
      name: label,
      x: x,
      y: y,
      value: 1,
      symbol: "rectangle",
      symbolSize: [160, 64],
      label: {
        show: true,
        position: "inside",
        textStyle: {
          fontSize: 12,
          color: "#000"
        },
        formatter: () => {
          return i18n.t(label);
        }
      },
      itemStyle: {
        normal: {
          color: "transparent"
        }
      }
    };

    // opt["itemStyle"] = transparentLabel;
    return opt;
  };

  // const lineStyleBlue = {
  //   normal: {
  //     opacity: 0.9,
  //     color: "#b0c090",
  //     type: "solid",
  //     width: 1.3
  //   }
  // };

  const lineStyleDashed = {
    normal: {
      opacity: 0.9,
      color: "orange",
      type: "dashed",
      width: 1.3
    }
  };

  const setupNode1 = (label, x, y) => {
    const opt = {
      name: label,
      x: x,
      y: y,
      z: 100,
      symbol: "circle",
      symbolSize: 1,
      symbolOffset: [1.2, 0],
      label: {
        position: "inside",
        show: false
      },
      itemStyle: {
        normal: {
          color: "blue"
        }
      }
    };
    return opt;
  };

  const setupLink = (source, target, lineStyle?) => {
    const opt = { source: source, target: target };

    if (lineStyle != undefined) {
      opt["lineStyle"] = lineStyle;
    }

    return opt;
  };

  const opt = {
    title: {
      top: "top",
      left: "left",
      text: i18n.t("org-title"),
      textStyle: {
        color: "green",
        fontWeight: "normal",
        fontSize: 42
      }
    },
    tooltip: {
      trigger: "none"
      // formatter: function (para) {
      //   // if (para.name != "x" || para.name != "y") {
      //   //   return para.name;
      //   // } else {
      //   //   return "";
      //   // }
      // }
    },
    animationDurationUpdate: 1500,
    animationEasingUpdate: "quinticInOut",
    textStyle: {
      color: "#000"
    },
    series: [
      {
        type: "graph",
        top: 110,
        left: "left",
        layout: "none",
        tooltip: {
          backgroundColor: "skyblue"
          //formatter: "{b} <br/>{a} : {c} h "
        },
        symbolSize: 1,
        roam: false,
        label: {
          normal: {
            show: true,
            position: "inside",
            textStyle: {
              fontSize: 12,
              color: "#fff",
              fontWeight: "BOLD"
            }
          }
        },
        edgeSymbol: ["none", "none"],
        edgeSymbolSize: [1, 1],

        data: [
          // root
          setupLabel("label-president", 100, 0),
          setupLabel("label-board", 250, 0),

          // level 1
          setupLabel("label-compliance", 150, 80),
          setupLabel("label-executive", 350, 80),
          setupLabel("label-investment", 475, 80),
          setupLabel("label-treasury", 600, 80),

          // level 2
          setupLabel("label-administration", 150, 160, "orange"),
          setupLabel("label-finance", 300, 160, "orange"),
          // level 3
          setupLabel("label-service", 220, 210, "orange"),

          // level 1 - Node
          setupNode1("node-a1", 220, 0),
          setupNode1("node-a2", 220, 40),

          setupNode1("node-b1", 250, 40),
          setupNode1("node-b2", 250, 120),

          setupNode1("node-compliance", 150, 40),
          setupNode1("node-executive", 350, 40),
          setupNode1("node-investment", 475, 40),
          setupNode1("node-treasury", 600, 40),

          // level 2
          setupNode1("node-administration", 150, 120),
          setupNode1("node-finance", 300, 120),

          // level 3
          setupNode1("node-service-1", 150, 210),
          setupNode1("node-service-2", 300, 210),

          setupText("board-level", 690, 100),
          setupText("project-level", 690, 125),

          setupNode1("p0", 60, 110),
          setupNode1("p1", 720, 110)
        ],
        // link the object together
        links: [
          setupLink("label-president", "label-board"),

          setupLink("node-compliance", "label-compliance"),
          setupLink("node-executive", "label-executive"),
          setupLink("node-investment", "label-investment"),
          setupLink("node-treasury", "label-treasury"),

          setupLink("node-a1", "node-a2"),
          setupLink("node-b1", "node-treasury"),
          setupLink("node-compliance", "node-a2"),
          // setupLink("node1-b", "node1-d"),

          // level 2
          setupLink("node-administration", "label-administration"),
          setupLink("node-finance", "label-finance"),
          setupLink("node-administration", "node-finance"),
          setupLink("label-board", "node-b2"),

          // level 3
          setupLink("label-administration", "node-service-1"),
          setupLink("label-service", "node-service-1"),
          setupLink("label-finance", "node-service-2"),
          setupLink("label-service", "node-service-2"),
          {
            source: "p0",
            target: "p1",
            lineStyle: lineStyleDashed
          }
        ],
        lineStyle: {
          normal: {
            opacity: 1,
            color: "#b0c090",
            type: "solid",
            curveness: 0,
            width: 1.3
          }
        }
      }
    ]
  };

  return opt;
};
