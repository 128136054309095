import request from "@/utils/request";

export const login = (Username, Password) => {
  return request.post(
    `/users/authenticate`,
    {
      Username,
      Password
    },
    {
      headers: { "Content-Type": "application/json;charset=UTF-8" }
    }
  );
};

export const mpgsCreateCheckoutSession = (obj: any) => {
  return request.post(`/api/v1/MpgsCreateCheckoutSession`, obj);
};

export const tngRequestQrCode = (obj: any) => {
  return request.post(`/api/v1/TngRequestQrCode`, obj);
};

export const tngGetTransactionInfo = (obj: any) => {
  return request.post(`/api/v1/TngGetTransactionInfo`, obj);
};
