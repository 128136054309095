





























/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeUnmount, onMounted, reactive } from "@vue/composition-api";
import * as api from "@/services";
import { IConfirmPayment } from "@/interface/confirmPayment";

export default defineComponent({
  components: {
    MpgsPaymentCard: () => import("@/views/components/paymentCreditCard/mpgs-payment-card.vue"),
    MpgsSucceedCard: () => import("@/views/components/paymentCreditCard/mpgs-succeed-card.vue"),
    MpgsFailedCard: () => import("@/views/components/paymentCreditCard/mpgs-failed-card.vue"),
    MpgsCanceledCard: () => import("@/views/components/paymentCreditCard/mpgs-canceled-card.vue")
  },
  setup(props, { root }) {
    const state = reactive({
      name: "Name",
      email: "",
      amount: 0,
      remarks: "",
      loading: false,
      sessionId: null,
      successIndicator: null,
      orderNo: "",
      step: 1,
      order: {} as any,
      error: null
    });

    const mpgsGatewayUrl =
      "https://dahsingbank.gateway.mastercard.com/checkout/version/51/checkout.js";

    onMounted(() => {
      (window as any).cancelCallback = () => cancelCallback();
      (window as any).errorCallback = (e) => errorCallback(e);
      (window as any).completeCallback = (e) => completeCallback(e);

      // Add programmaticaly the external Bing maps api script
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("data-cancel", "cancelCallback");
      scriptTag.setAttribute("data-error", "errorCallback");
      scriptTag.setAttribute("data-complete", "completeCallback");
      scriptTag.setAttribute("src", mpgsGatewayUrl);

      scriptTag.id = "checkout-div";

      // Inject the dynamic script in the DOM
      document.head.appendChild(scriptTag);
    });

    onBeforeUnmount(() => {
      while (document.getElementById("checkout-div") != undefined) {
        const scriptTag = document.getElementById("checkout-div");
        scriptTag.parentNode.removeChild(scriptTag);
      }
    });

    function config(sessionId, merchantId, orderNo, amount, email) {
      const config = {} as any;
      config.merchant = merchantId;
      config.order = {
        id: orderNo,
        amount: amount,
        currency: "HKD",
        description: "Donation to AG Children Foundation"
      };
      config.session = {
        id: sessionId
      };
      config.customer = {
        email: email
      };
      config.interaction = {
        merchant: {
          name: root.$t("mpgs-company"),
          address: {
            line1: "Kowloon",
            line2: "Hong Kong"
          }
        },
        locale: root.$t("mpgs-locale"),
        displayControl: {
          billingAddress: "HIDE",
          customerEmail: "HIDE",
          orderSummary: "HIDE",
          shipping: "HIDE"
        }
      };

      return config;
    }

    async function handleConfirmPayment(input) {
      // alert(JSON.stringify(input));

      state.amount = input.amount;
      state.email = input.email;

      try {
        state.loading = true;
        const params: IConfirmPayment = {} as IConfirmPayment;
        params.name = input.name;
        params.email = input.email;
        params.amount = input.amount;
        params.remarks = input.remarks;

        await api.mpgsCreateCheckoutSession(params).then((response) => {
          //this.loading = false;
          const data = response as any;
          // alert(JSON.stringify(data));

          if (data.result == "SUCCESS") {
            state.sessionId = data.session.id;
            state.orderNo = data.orderNo;
            state.successIndicator = data.successIndicator;

            const _config = config(
              data.session.id,
              data.merchantId,
              data.orderNo,
              state.amount,
              state.email
            );

            (window as any).Checkout.configure(_config);
            (window as any).Checkout.showLightbox();
          } else {
            alert("fail mpgsCreateCheckoutSession");
          }
        });
      } catch (e) {
        //  this.showQrcode = false;
        state.loading = false;
        alert("catch" + JSON.stringify(e));
        // console.log("fetch data failed..", e);
      } finally {
        //
      }
    }

    function completeCallback(response) {
      const resultIndicator = response;

      if (resultIndicator === state.successIndicator) {
        gotoStep(2);
        state.order.amount = state.amount;
        state.order.orderNo = state.orderNo;
      } else {
        gotoStep(3);
      }
    }

    function errorCallback(error) {
      state.error = error;
      gotoStep(3);
    }

    function cancelCallback() {
      gotoStep(4);
    }

    function gotoStep(step) {
      setTimeout(() => {
        state.step = step;
      }, 300);
    }

    return { state, handleConfirmPayment };
  }
});
