








































import { computed, defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    HsbcBeneficiaryCard: () => import("@/views/components/donation/hsbc_beneficiary_card.vue"),
    OnlineDonationCard: () => import("@/views/components/donation/online-donation-card.vue")
    // DialogPrivacyPolicyEn: () => import("@/components/dialog/dialog-privacy-policy-en.vue"),
    // DialogPrivacyPolicyZh: () => import("@/components/dialog/dialog-privacy-policy-zh.vue")
  },
  setup() {
    const background = computed(() => require("@/assets/img/bg_donation.svg"));

    const dialog = ref(true);
    return { background };
  }
});
