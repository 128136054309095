










import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    PrivacyPolicyEn: () => import("@/views/components/privacyPolicy/privacy-policy-en.vue"),
    PrivacyPolicyZh: () => import("@/views/components/privacyPolicy/privacy-policy-zh.vue")
  },
  props: {
    agreeType: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const getAgreeType = computed(() => {
      if (props.agreeType == "tng") {
        return { path: "/payment-tng" };
      } else {
        return { path: "/payment-credit-card" };
      }
    });
    return { getAgreeType };
  }
});
