


















































import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const backgroundImage = computed(function () {
      return require("@/assets/img/child.jpg");
    });

    const headingEn = "AG Children Foundation Limited";
    const headingZh = "思捷兒童基金有限公司";

    const paraEn = "Tax exempted under Section 88 of Inland Revenue Ordinance";
    const paraZh = "領有慈善機構免稅地位";
    const refNo = "Ref no:91/14098";
    const link = "https://www.ird.gov.hk/eng/tax/ach_search.htm";

    const email = "Email: info@agcf.org.hk";
    const telephone = "Tel no: (852)2333 1149";
    const whatsApp = "WhatsApp no: (852)6983 6883";
    const weChat = "Wechat ID: AGCF2020";

    return {
      backgroundImage,
      headingEn,
      headingZh,
      paraEn,
      paraZh,
      refNo,
      link,
      email,
      telephone,
      whatsApp,
      weChat
    };
  }
});
