





























import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  setup(_, { root }) {
    const about = computed(function () {
      return [
        {
          img: require("@/assets/img/multi-user.png"),
          icon: "fas fa-users",
          title: root.$t("subtitle-background"),
          color: "green darken-1",
          textColor: "green--text text--darken-1",
          paragraph: root.$t("paragraph-background")
        },
        {
          img: require("@/assets/img/child-care.png"),
          icon: "fas fa-eye",
          title: root.$t("subtitle-vision"),
          color: "amber lighten-1",
          textColor: "amber--text text--lighten-1",
          paragraph: root.$t("paragraph-vision")
        },
        {
          img: require("@/assets/img/tick.png"),
          icon: "fas fa-check",
          title: root.$t("subtitle-mission"),
          color: "orange",
          textColor: "orange--text",
          paragraph: root.$t("paragraph-mission")
        },
        {
          img: require("@/assets/img/heart.png"),
          icon: "fas fa-heart",
          title: root.$t("subtitle-belief"),
          color: "red darken-2",
          textColor: "red--text text--darken-2",
          paragraph: root.$t("paragraph-belief")
        }
      ];
    });

    const background = computed(function () {
      return require("@/assets/img/bg_about.svg");
    });

    return {
      about,
      background
    };
  }
});
