





















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const msg = "Sorry, the page you're looking for doesn't exist.";
    return { msg };
  }
});
