

































import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  components: {
    ServicesEn: () => import("@/views/components/services/services-en.vue"),
    ServicesZh: () => import("@/views/components/services/services-zh.vue")
  },
  setup() {
    const dialog = ref(false);
    const someText = ref("");

    return { dialog, someText };
  }
});
